.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1677ff;
    height: 40px;
    padding-top:5px;
  }
  
  .title {
    color: #fff !important;
    margin: 0;
  }