.card {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .card-image {
    margin-right: 10px;
    height: 100%;

  }
  
  .card-duration {
    margin-bottom: 5px;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .card-title {
    /* font-size: 18px; */
    font-weight: bold;
  }
  